import React, { useState, useEffect } from 'react';
import { getVacationRecommendation, fetchVacation } from '../api/vacationsAPI'; // Assuming you have getVacationById to fetch by ID
import './VacationList.css'; // Reuse the existing CSS for the card styling
import { Vacation } from '../types/vacationType'; // Importing the Vacation type

const VacationRecommendation: React.FC = () => {
    const [preferences, setPreferences] = useState<string>(''); // Ensure preferences is a string
    const [recommendation, setRecommendation] = useState<Vacation | null>(null);  // Store the full vacation recommendation
    const [loading, setLoading] = useState<boolean>(false);

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setPreferences(e.target.value);
    };

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        if (preferences.trim()) {
            setLoading(true);
            try {
                const result = await getVacationRecommendation(preferences);
                
                // Extract the JSON part from the response using a more precise regex
                const jsonMatch = result.match(/{[\s\S]*?}/); // This will match the first JSON-like object even with line breaks
                if (jsonMatch) {
                    try {
                        const parsedResult = JSON.parse(jsonMatch[0]); // Parse the matched JSON string
                        if (parsedResult.vacation_id) {
                            // Fetch the full vacation details by ID
                            const vacationDetails = await fetchVacation(parsedResult.vacation_id);
                            setRecommendation(vacationDetails);
                        }
                    } catch (jsonError) {
                        console.error('Error parsing JSON:', jsonError);
                    }
                } else {
                    console.error('No valid JSON found in the response.');
                }
            } catch (error) {
                console.error('Error fetching recommendation:', error);
            } finally {
                setLoading(false);
            }
        }
    };
    
    

    const renderRecommendationCard = (vacation: Vacation) => {
        return (
            <div key={vacation.vacation_id} className="vacation-card">
                <div className="image-container">
                    {/* Use a placeholder image or the vacation's image if available */}
                    <img
                        src={vacation.image_filename}
                        alt={vacation.destination}
                        className="vacation-image"
                    />
                </div>
                <div className="vacation-details">
                    <h2>{vacation.destination}</h2>
                    <p className="description">{vacation.description}</p>
                    <p>Start Date: {new Date(vacation.start_date).toLocaleDateString()}</p>
                    <p>End Date: {new Date(vacation.end_date).toLocaleDateString()}</p>
                    <p className="price">Price: ${parseFloat(vacation.price).toFixed(2)}</p>
                    <p>Followers: {vacation.followersCount}</p>
                </div>
            </div>
        );
    };

    return (
        <div className="vacation-recommendation">
            <h2>Find a Vacation</h2>
            <form onSubmit={handleSubmit}>
                <input
                    type="text"
                    placeholder="Enter your vacation preferences"
                    value={preferences}
                    onChange={handleInputChange}
                />
                <button type="submit">Get Recommendation</button>
            </form>
            <div className="recommendation">
                {loading ? <p>Loading...</p> : recommendation && renderRecommendationCard(recommendation)}
            </div>
        </div>
    );
};

export default VacationRecommendation;
